<template>

    <span class="mangelCarousel" v-if="images && images.length > 0">

        <b-carousel data-interval="false"
            id="carousel-fade"
            fade
            :indicators="indicators"
            :interval="0"
            :controls="controls">

            <b-carousel-slide
                v-for="image in images"
                class="align-middle"
                :key="image.id">
              <template #img>
                <div class="d-flex justify-content-center align-items-center min-vh-100">
                  <img
                      class="d-block img-fluid"
                      @click="showLarge(image)"
                      :src="image.url"
                      alt=""
                      style="cursor: pointer;">
                </div>
              </template>
              <div style="text-shadow: 1px 1px rgba(0, 0, 0, 1);color:#e9ecef">

                {{ $t('message.imageCarousel_aufgenommen_am') }}:
                <java-date-to-default-date-format v-model="image.datum" />
                <!-- löschen -->
                <b-button
                    variant="danger"
                    size="sm"
                    class="float-right"
                    @click="deleteImage(image)"
                    style="font-size: 0.6rem;opacity: 0.6">
                  <b-icon icon="trash"></b-icon>
                </b-button>

              </div>
            </b-carousel-slide>

        </b-carousel>

        <b-modal id="large-img" :title="$t('message.imageCarousel_detailansicht')" size="xl">

          <span v-if="image">
            <img class="w-100" :src="image.url" />
          </span>

          <template #modal-footer>
            <div class="w-100">

              <b-button
                  variant="danger"
                  size="sm"
                  class="float-right"
                  @click="deleteImage(image)">
                <b-icon icon="trash"></b-icon>&nbsp;{{ $t("message.default_loeschen") }}
              </b-button>
            </div>
          </template>

        </b-modal>

    </span>

</template>

<script>

    import $ from 'jquery';
    import JavaDateToDefaultDateFormat from "@/components/layout/JavaDateToDefaultDateFormat.vue";
    import bootbox from "bootbox";
    import axios from "axios";

    // Define a new component
    export default {
        name: 'ImageCarousel',
        components: {
          JavaDateToDefaultDateFormat
        },
        props: {

            /** Bilder die angezeigt werden sollen */
            mangel: {
                type: Object,
                required: true,
            }

        },
        /*
          Defines the data used by the component
        */
        data: function () {
            return {
              controls: null,
              indicators: null,
              images: null,
              /** Bild in Detailansicht */
              image: null,
            }
        },
        mounted: function () {

          if (this.mangel) {

            this.images = this.mangel.bilder
            $('.carousel').carousel()
            if (this.images && this.images.length > 1) {

              this.controls = 'controls'
              this.indicators = 'indicators'

            }

          }

        },
        methods: {

          /** Bild in Modal anzeigen */
          showLarge: function (obj) {

            console.log('showLarge', obj)
            this.image = obj
            this.$bvModal.show('large-img')

          },
          /**
           * ein Bild löschen
           */
          deleteImage: function (obj) {

            const that = this
            const text = this.$t('message.imageCarousel_delete_sure')
            bootbox.confirm(text, function(result) {
              if (result) {

                that.showSpinner()
                const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/deleteMangelBild'
                const params = {
                  id: obj.id,
                }
                axios ({
                  method:   'GET',
                  params:   params,
                  url:      url
                }).then(function () {

                  that.$emit('image-deleted', {
                    mangel: that.mangel,
                    image: obj,
                  })
                  for (let i = 0; i < that.images.length; i++) {
                    if (that.images[i].id === obj.id) {
                      that.images.splice(i, 1)
                    }
                  }
                  that.hideSpinner()
                  that.$bvModal.hide('large-img')

                }).catch(error => {
                  console.log(error)
                  alert('Es gab einen Fehler beim Löschen des Bildes. Bitte wenden Sie sich an einen Biotech Mitarbeiter.')
                })

              }
            })

          },

        }
    }

</script>

<style scoped>

    .img-fluid {
        max-height: 400px;
        min-height: 100px;
    }

</style>
